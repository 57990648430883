.detail-page {
  text-align: center;
  min-height: calc(var(--app-height) - 60px);
}

.detail-page .detail-image {
  max-width: 90%;
  max-height: 90vh;
  margin: auto;
  border: 4px solid #1a1a1a;
  border-radius: 8px;
  margin-bottom: 32px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px 0px;
}

.detail-page .copy-button {
  width: 90%;
  margin: 16px auto;
}

.detail-page .line {
  width: 66%;
  margin: 32px auto;
}

.detail-page {
  padding-bottom: 64px;
}
